import React, {useState} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Env from "../Constant";
// import { BsXLg } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import PrimeLogo from ".././Assets/Images/Prime_Logo_RGB_WHITE.svg";
import InfluencersData from "../../Partials/Influencers.json";

function Nav() {
  
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const navigate = useNavigate();
  const isHomePage = pathname === '/';

  const [sidebar, setSidebar] = useState(false);
  const { t } = useTranslation();

  const scrollToTop = (event) => {
    if (isHomePage) {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const influencers = InfluencersData.map((influencer) => (
    <li
      key={influencer.id}
      onClick={() => {
        setSidebar(false);
        if(!Env.COMING_SOON){
          navigate(`influencer/${influencer.slug}`);
        }
        
      }}
      className={splitLocation[2] === `${influencer.slug}` ? "active" : ""}
    >
      {" "}
      <Link to={Env.COMING_SOON ? ``: `influencer/${influencer.slug}`}>{Env.APP_LANG=='en'? influencer.name : influencer.name_fr}</Link>
      {/* <a href={Env.COMING_SOON ? ``: `${Env.APP_URL}/influencer/${influencer.slug}`}>{Env.APP_LANG=='en'? influencer.name : influencer.name_fr}</a> */}
    </li>
  ));



  return (
    <>
      <div className="navLeft">
        <button
          className="hamburger"
          onClick={() => {
            setSidebar(true);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        {/* after removing the comment of hamburger menu remove the css from index.css file */}
        <div className="primeVideoLink">
          <Link to={Env.PRIME} target='_blank'>
            <img src={PrimeLogo} alt="PrimeLogo" />
          </Link>
        </div>
      </div>
      <div className="navCenter">
        <Link
          to={`${Env.APP_URL}`}
          onClick={scrollToTop}
          dangerouslySetInnerHTML={{ __html: t("header.navcenter.caption") }}
        />
      </div>
      <div className="navRight">
        <Link to={`${Env.APP_EN_URL}${pathname}`} className={Env.APP_LANG == 'en' ? "active" : ""}>EN</Link>
        <Link to={`${Env.APP_FR_URL}${pathname}`} className={Env.APP_LANG == 'fr' ? "active" : ""}>FR</Link>
      </div>

      <div
        id="responsive-navbar-nav"
        className={`sideMenus ${sidebar ? "show" : ""}`}
      >
        <div className="crosbtn">
          <div className="crosbtnInner"
            onClick={() => {
              setSidebar(false);
            }}></div>
        </div>
        <ul className="d-flex">
          {influencers}
        </ul>
      </div>

      <div
        onClick={() => {
          setSidebar(false);
        }}
        className={`side ${sidebar ? "show" : ""}`}
      ></div>
    </>
  );
}

export default Nav;
