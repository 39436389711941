import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import Locale from './Components/Utils/Locale/Locale';
import i18n from './Components/Utils/Locale/i18';
import './index.css';
import App from './App';
import Home from './Components/Home/Home';
import Influencer from './Components/Influencer/Influencer';
import reportWebVitals from './reportWebVitals';
// import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <HelmetProvider> */}
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Locale />} /> */}
          {/* <Route path="/:lang/*" element={<App />}> */}
          
          <Route path="/" element={<App />} >
              <Route path="" element={<Home />} />
              <Route path="influencer/:slug" element={<Influencer />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
    {/* </HelmetProvider>    */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
