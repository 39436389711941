import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Env from './Components/Utils/Partials/Constant';
import Nav from './Components/Utils/Partials/Header/Nav';
import Footer from './Components/Utils/Partials/Footer/Footer';
import {checkLang} from './Components/Utils/Partials/Functions';
// import './App.css';
import './Components/Utils/Partials/Assets/App.scss';
import ScrollToTop from './Components/ScrollToTop';
// import { Helmet } from 'react-helmet-async';

function App() {
  const [headerTop, setHeader] = useState(false);
  const { t, i18n } = useTranslation();
  const lang  = Env.APP_LANG;
  useEffect(() => {
    checkLang(i18n, lang)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
})
  const isSticky = (e) => {
    //const header = document.querySelector('.header-section');
    //const scrollTop = window.scrollY;
    //scrollTop >= 0 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
};

  return (
    
      
      <div className={`App ${Env.APP_LANG}`}>
       
        <header className="header-section App-header is-sticky">
          <div className="headerInner">
            <Nav />
          </div>
          <div className="banner_bar" style={{backgroundImage:  `url(${Env.APP_URL}/assets/Images/Bggradient.jpg)`}}></div>
        </header>
        <Outlet />
        <ScrollToTop />
        <footer><Footer /></footer>
      </div>
    
  );
}

export default App;
