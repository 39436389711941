import React from "react";
import { Link } from "react-router-dom";
import Styles from "./Influencers.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfluencersData from "../../Utils/Partials/Influencers.json";
import Env from "../../Utils/Partials/Constant";
import ArrowLeft from "../../Utils/Partials/Assets/Images/ArrowLeft.png";
import ArrowRight from "../../Utils/Partials/Assets/Images/ArrowRight.png";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from "react-i18next";

function Influencers() {

  
  const { t } = useTranslation();

  const settings = {
    rows: 2,
    //lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 2, // Change the number of slides to show per row
    slidesToScroll: 2, // Change the number of slides to scroll per action
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Display 2 slides per row on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        //style={{ ...style, display: "block", background: "red" }}
        style={{ backgroundImage: `url(${ArrowRight})` }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ backgroundImage: `url(${ArrowLeft})` }}
        onClick={onClick}
      />
    );
  }

  // const sliderItems = InfluencersData.map((influencer) => (
  //   <div key={influencer.id} className={Styles.slide}>
  //     <Link to={`influencer/${influencer.slug}`} >
  //       <img width={200} src={`${Env.APP_URL}/assets/influencers/thumbnail/${influencer.flick_pick_img}`} alt={influencer.name} />
  //       <div className={Styles.overlay}>
  //         <h3>{influencer.name}</h3>
  //       </div>
  //     </Link>
  //   </div>
  // ));

  const sliderItems = InfluencersData.map((influencer) => ({
    ...influencer,
    id: Math.random(),
  }))
    .sort((a, b) => a.id - b.id)
    .map((influencer, index) => (
      <div
        key={`sliderItem_${influencer.id}_${index}`}
        className={Styles.slide}
      >
        <Link to={Env.COMING_SOON ? `` : `influencer/${influencer.slug}`}>
          <img
            width={200}
            src={`${Env.APP_URL}/assets/influencers/thumbnail/${influencer.image}`}
            alt={Env.APP_LANG=='en'? influencer.name : influencer.name_fr}
          />
          <div className={`${Styles.overlay} ${Env.COMING_SOON ? 'coming_soon_Overlay' : ''}`}>
            <h3 className={Env.COMING_SOON ? 'coming_soon_h3' : ''}>{Env.COMING_SOON ? `${t("home.influencer.coming_soon")}` : Env.APP_LANG=='en'? influencer.name : influencer.name_fr}</h3>
          </div>
        </Link>
      </div>
    ));


  

  
 
  



  return (
    <div className={Styles.home_slider_parent}>
      <div className={Styles.container}>

        {InfluencersData ? <Slider {...settings}>{sliderItems}</Slider> : <Skeleton />}
        
      </div>
    </div>
  );
}

export default Influencers;
