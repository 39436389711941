import React, {useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./Narratives.module.scss";
import Env from "../../Utils/Partials/Constant";
import GovernanceSuggestion from '../../Utils/Partials/Assets/Images/GovernanceSuggestion.svg';
import CoreBrandHigh from '../../Utils/Partials/Assets/Images/CoreBrandHigh.svg';
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
function Narratives() {

  useEffect(() => { 
    AOS.init({
      once: true
    }); 
  }, []) 
  const { t } = useTranslation();

  return (
    <div className={`${Styles.home_narratives_parent} home_narrativesCustom`}>
      <div className={Styles.container}>
          <div className={Styles.narratives_inner} data-aos="fade-up">
              <h2 dangerouslySetInnerHTML={{ __html: t("home.narrative.narrative_main_title") }} />
              <h5 dangerouslySetInnerHTML={{ __html: t("home.narrative.narrative_sub_title") }} /> 
              <p dangerouslySetInnerHTML={{ __html: t("home.narrative.narrative_para_one") }} /> 
            </div>
          <div className={Styles.narratives_feature} data-aos="fade-up">
              <div className={Styles.narratives_feature_box}>
                <Link to={Env.INCLUSION_PLAYBOOK} target='_blank'><img src={`${Env.APP_URL}/assets/Images/GovernanceSuggestion.svg`} alt="GovernanceSuggestion" /></Link>
                <p dangerouslySetInnerHTML={{ __html: t("home.narrative.feature_one_bold") }} /> 
                <p dangerouslySetInnerHTML={{ __html: t("home.narrative.feature_one") }} /> 
              </div>
              <div className={Styles.narratives_feature_box} data-aos="fade-up">
                <Link to={Env.PRIDE_TORONTO} target='_blank'><img src={`${Env.APP_URL}/assets/Images/CoreBrandHigh.svg`} alt="CoreBrandHigh" /></Link>
                <p dangerouslySetInnerHTML={{ __html: t("home.narrative.feature_two") }} /> 
              </div>
          </div>
      </div>
  </div>
  );
}

export default Narratives;
