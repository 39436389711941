import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import style from "./BannerVideo.module.scss";
import Env from "../../Utils/Partials/Constant";
import PlayIcon from "../../Utils/Partials/Assets/Images/PlayIcon.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BannerVideo = ({ image, src, captionEn, captionFr }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [posterImage, setPosterImage] = useState(image);

  useEffect(() => {
   
    let playerInstance;

    if (src) {
      const videoElement = videoRef.current;

      // Initialize Video.js player
      playerInstance = videojs(videoElement, {}, () => {
        // Fullscreen configuration
        playerInstance.on("fullscreenchange", () => {
          const isExitingFullscreen = !playerInstance.isFullscreen();
          setIsFullscreen(playerInstance.isFullscreen());

          if (isExitingFullscreen) {
            setPosterImage(`${image}`);
            playerInstance.pause();
            playerInstance.currentTime(0);
            playerInstance.controls(false);
            playerInstance.load();
          } else {
            playerInstance.play();
            playerInstance.controls(true);
          }
        });
      });

      playerRef.current = playerInstance;
      playerInstance.src(src);
      playerInstance.poster(image);

      // Load captions dynamically
      playerInstance.ready(() => {

        setPosterImage(`${posterImage}`);

        // Remove existing captions
        const textTracks = playerInstance.textTracks();
        for (let i = textTracks.length - 1; i >= 0; i--) {
          playerInstance.removeRemoteTextTrack(textTracks[i]);
        }

        // Add English caption track dynamically
        //console.log ("captionEn", captionEn)
        if (captionEn) {
          playerInstance.addRemoteTextTrack({
            kind: "captions",
            src: `${Env.APP_URL}/${captionEn}`,
            srclang: "en",
            label: "English",
            default: Env.APP_LANG == "en"
          });
        }

        // Add French caption track dynamically
        //console.log ("captionFr", captionFr)
        if (captionFr) {
          playerInstance.addRemoteTextTrack({
            kind: "captions",
            src: `${Env.APP_URL}/${captionFr}`,
            srclang: "fr",
            label: "Français",
            default: Env.APP_LANG == "fr"
          });
        }
      });
    }

    // Cleanup function to destroy the video elements
    // return () => {
    //   if (playerInstance) {
    //     playerInstance.dispose();
    //   }
    // };
  }, [src, image, captionEn, captionFr]);

  const fullscreen = () => {
    const playerInstance = playerRef.current;
    if (playerInstance && !isFullscreen) {
      playerInstance.requestFullscreen();
    } else if (playerInstance && isFullscreen) {
      playerInstance.exitFullscreen();
    }
  };

  return (
    <>
      <div className={style.video_container}>
        {src ? (
          <>
            <video ref={videoRef} className="video-js vjs-default-skin" />
            {!isFullscreen && (
              <img
                className={style.playbutton}
                src={PlayIcon}
                alt="Play Button"
                onClick={fullscreen}
              />
            )}
          </>
        ) : (
          <Skeleton
            width={400}
            height={400}
            baseColor="#faf8f6"
            highlightColor="#7adef5"
            duration={0.9}
            count={1}
          />
        )}
      </div>
    </>
  );
};

export default BannerVideo;
