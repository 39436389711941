import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./Bio.module.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Bio({ name, handle, bio, pronoun }) {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);
  return (
    <div className={style.In_bio}>
      <div className={style.container}>
        <div className={style.bioinner} data-aos="fade-up">
          <h1>
            {`${name}` || (
              <Skeleton
                width={400}
                baseColor="#faf8f6"
                highlightColor="#7adef5"
                duration={0.9}
                count={1}
              />
            )}
 <span>{`(${pronoun})`}</span>
          </h1> 
          <h5>
          <Link to={`https://www.instagram.com/${handle}`} target='_blank'>
          {`@${handle}` || (
              <Skeleton
                width={400}
                baseColor="#faf8f6"
                highlightColor="#7adef5"
                duration={0.9}
                count={1}
              />
            )}                        
          </Link>
          </h5>
          <p>
            {bio || (
              <Skeleton
                width={400}
                baseColor="#faf8f6"
                highlightColor="#7adef5"
                duration={0.9}
                count={2}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
