import React, {useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import style from "./FlickPick.module.scss";
import LazyLoad from "react-lazyload";
import Env from "../../Utils/Partials/Constant";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

function FlickPick({ image, content, link, name }) {
  useEffect(() => { 
    AOS.init({
      once: true
    }); 
  }, []) 
  const { t } = useTranslation();
  return (
    <div className={style.flickpick}>
      <div className={style.container}>
        <div className={style.flickImg} data-aos="fade-up">
          <div className={style.flickImgInner}>
            <LazyLoad height={200} once>
              {image ? (
                <img
                width={200}
                src={`${Env.APP_URL}/assets/influencers/flickpick/${Env.APP_LANG}/${image}`}
                alt={`${name}'s Flick Pick`}
              />
              ) : (
                <Skeleton width={400} height={400} baseColor="#faf8f6" highlightColor="#7adef5" duration={0.9} count={1} />
              )}
              
            </LazyLoad>
          </div>
        </div>
        <div className={style.flickText} data-aos="fade-up">
          <h2 dangerouslySetInnerHTML={{ __html: t("influencer.flick_pick") }} />
          <p>{content || <Skeleton width={400} baseColor="#faf8f6" highlightColor="#7adef5" duration={0.9} count={5} />}</p>
          {link ? (
            <Link to={Env.PRIME} target="_blank" className={style.watchBtn}>
            {t("influencer.watch_now")}
          </Link>
          ) : (
            <Skeleton width={100} height={30} baseColor="#faf8f6" highlightColor="#7adef5" duration={0.9} count={1} />
          )}
        </div>
      </div>
      
    </div>
  );
}

export default FlickPick;
