import React, {useState, useEffect }  from 'react';
import style from "./BehindScene.module.scss";
import { useTranslation } from "react-i18next";
import BannerVideo from '../BannerVideo/BannerVideo';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Env from "../../Utils/Partials/Constant";
import ArrowLeft from "../../Utils/Partials/Assets/Images/ArrowLeft.png";
import ArrowRight from "../../Utils/Partials/Assets/Images/ArrowRight.png";


function BehindScene({slug,image, src , content, captionEn, captionFr}) {
  useEffect(() => { 
    AOS.init({
      once: true
    }); 
  }, []) ;

  const [videoInx, setVideoInx] = useState(0);

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        //style={{ ...style, display: "block", background: "red" }}
        style={{ backgroundImage: `url(${ArrowRight})` }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ backgroundImage: `url(${ArrowLeft})` }}
        onClick={onClick}
      />
    );
  }
  
  function onNextClick (e){
    if (videoInx == src.length-1){
      setVideoInx(0);
    }else {
      setVideoInx(videoInx+1);
    }
  } 

  function onPrevClick (e){
    if (videoInx == 0){
      setVideoInx(src.length-1);
    }else {
      setVideoInx(videoInx-1);
    }
    
  } 

  const { t } = useTranslation();
  return (
    <div className={style.behindscene} data-aos="fade-up">
      <div className={style.container}>
        <div className={style.behindsceneText}>
          <h2 dangerouslySetInnerHTML={{ __html: t("influencer.behind_the_scene") }} />
          {/* <p>{content || <Skeleton width={400} baseColor="#faf8f6" highlightColor="#7adef5" duration={0.9} count={5} />}</p> */}
        </div>  
        <div className={style.behindsceneImg}>
          <PrevArrow className={style.arrow} onClick={onPrevClick} />
          { Env.APP_LANG == "en" &&
            <>
              <BannerVideo image={`${Env.APP_URL}/assets/influencers/videos/commentary/thumbnail/${slug}/${src[videoInx].image}`}  src={`${Env.APP_URL}/assets/influencers/videos/commentary/${Env.APP_LANG}/${src[videoInx].video}`} />
            </>
          }

          { Env.APP_LANG == "fr" &&
            <>
              <BannerVideo image={`${Env.APP_URL}/assets/influencers/videos/commentary/thumbnail/${slug}/${src[videoInx].image}`}  src={`${Env.APP_URL}/assets/influencers/videos/commentary/${Env.APP_LANG}/${src[videoInx].video}`} 
                captionFr={`assets/influencers/videos/commentary/caption/${Env.APP_LANG}/${src[videoInx].captionFr}`}
              />
            </>
          }
          
          <NextArrow className={style.arrow} onClick={onNextClick} />
        </div>
      </div>
    </div>
  )
}

export default BehindScene