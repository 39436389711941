import React, {useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./Pride.module.scss";
import Env from "../../Utils/Partials/Constant";
import AffiliateEvent from '../../Utils/Partials/Assets/Images/AffiliateEvent.png';
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Prides() {
  useEffect(() => { 
    AOS.init({
      once: true
    }); 
  }, []) 
  const { t } = useTranslation();
  return (
    <div className={Styles.influencer_pride_parent}>
        <div className={Styles.container}>
            <div className={Styles.influencer_pride_inner} data-aos="fade-up">
                <p dangerouslySetInnerHTML={{ __html: t("home.pride.pride_caption") }} /> 
                <Link to={Env.PRIDE_TORONTO} target='_blank'><img src={`${Env.APP_URL}/assets/Images/AffiliateEvent.png`} alt='{t("home.pride.pride_caption")}' /></Link>
            </div>
        </div>
    </div>
  );
}

export default Prides;
