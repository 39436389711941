import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import style from "./Influencer.module.scss";
import InfluencersData from "../Utils/Partials/Influencers.json";
import Env from "../Utils/Partials/Constant";
import HeaderBar from "./HeaderBar/HeaderBar";
import BannerVideo from "./BannerVideo/BannerVideo";
import Bio from "./Bio/Bio";
import FlickPick from "./FlickPick/FlickPick";
import BehindScene from "./BehindScene/BehindScene";
import PrideImg from "./Pride/Pride";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Influencer = () => {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  const { slug } = useParams();
  const lang = Env.APP_LANG;
  const [isLoading, setIsLoading] = useState(true);
  const [influencer, setInfluencer] = useState(null);

  const [name, setName] = useState(null);
  const [handle, setHandle] = useState(null);
  const [pronoun, setPronoun] = useState(null);
  const [image, setImage] = useState(null);
  const [quote, setQuote] = useState(null);
  const [bio, setBio] = useState(null);
  const [bannerVideo, setBannerVideo] = useState(null);
  const [flickPickImg, setFlickPickImg] = useState(null);
  const [flickPickbio, setFlickPickbio] = useState(null);
  const [flickPickLink, setFlickPickLink] = useState(null);
  const [commentary, setCommentary] = useState(null);
  const [commentaryVideo, setCommentaryVideo] = useState(null);
  const [caption, setCaption] = useState(null);

  useEffect(() => {
    const fetchedInfluencer = InfluencersData.find(
      (influencer) => influencer.slug === slug
    );
    setInfluencer(fetchedInfluencer);
    //console.log(fetchedInfluencer.caption);
    if (fetchedInfluencer) {
      
      setImage(fetchedInfluencer.poster_image);
      if (lang == "en") {
        setName(fetchedInfluencer.name);
        setPronoun(fetchedInfluencer.pronoun_en);
        setBio(fetchedInfluencer.bio_en);
        setQuote(fetchedInfluencer.quote_en);
        setFlickPickbio(fetchedInfluencer.flick_pick_en);
        setCommentary(fetchedInfluencer.commentary_en);
        setBannerVideo(fetchedInfluencer.video);
        setFlickPickImg(fetchedInfluencer.flick_pick_img);
        setCommentaryVideo(fetchedInfluencer.commentary_video_en);
      } else {
        setName(fetchedInfluencer.name_fr);
        setPronoun(fetchedInfluencer.pronoun_fr);
        setBio(fetchedInfluencer.bio_fr);
        setQuote(fetchedInfluencer.quote_fr);
        setFlickPickbio(fetchedInfluencer.flick_pick_fr);
        setCommentary(fetchedInfluencer.commentary_fr);
        setBannerVideo(fetchedInfluencer.video_fr);
        setFlickPickImg(fetchedInfluencer.flick_pick_img_fr);
        setCommentaryVideo(fetchedInfluencer.commentary_video_fr);
      }
      setHandle(fetchedInfluencer.handle);
      
      setFlickPickLink(fetchedInfluencer.flick_pick_link);
      setCaption(fetchedInfluencer.caption);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
console.log('image',image);

  }, [slug,influencer,image, caption, lang]);

  return (
    <>
      <div className={style.influencer}>
        {/* <HeaderBar /> */}

        { slug == 'gabriel' && Env.APP_LANG == "en" &&
         <>
          <BannerVideo
            image={`${Env.APP_URL}/assets/influencers/videos/thumbnail/${image}`}
            src={`${Env.APP_URL}/assets/influencers/videos/${Env.APP_LANG}/${bannerVideo}`}
            captionEn={`assets/influencers/videos/caption/en/${slug}.vtt`} default  
          />
         </>
        }

        { slug == 'gabriel' && Env.APP_LANG == "fr" &&
         <>
          <BannerVideo
            image={`${Env.APP_URL}/assets/influencers/videos/thumbnail/${image}`}
            src={`${Env.APP_URL}/assets/influencers/videos/${Env.APP_LANG}/${bannerVideo}`}
          />
         </>
        }

        { slug !== 'gabriel' && Env.APP_LANG == "fr" &&
         <>
          <BannerVideo
            image={`${Env.APP_URL}/assets/influencers/videos/thumbnail/${image}`}
            src={`${Env.APP_URL}/assets/influencers/videos/${Env.APP_LANG}/${bannerVideo}`}
            captionFr={`assets/influencers/videos/caption/fr/${slug}.vtt`} default  
          />
         </>
        }

        { slug !== 'gabriel' && Env.APP_LANG == "en" &&
         <>
          <BannerVideo
            image={`${Env.APP_URL}/assets/influencers/videos/thumbnail/${image}`}
            src={`${Env.APP_URL}/assets/influencers/videos/${Env.APP_LANG}/${bannerVideo}`}
          />
         </>
        }
        {
          /*
          <BannerVideo
          image={image}
          src={`${Env.APP_URL}/assets/influencers/videos/${Env.APP_LANG}/${bannerVideo}`}
          captionEn={`assets/influencers/videos/caption/en/${slug}.vtt`} 
          
          // captionEn={`assets/influencers/videos/caption/en/${caption}`}
          // captionFr={`assets/influencers/videos/caption/fr/${caption}`}
          />
          */
        }

        <Bio name={name} bio={bio} handle={handle} pronoun={pronoun} />
        <FlickPick
          image={flickPickImg}
          content={flickPickbio}
          link={flickPickLink}
          name={name}
        />
        {commentaryVideo != null ? (<BehindScene slug={slug} image={image} src={commentaryVideo} content={commentary} captionEn={`assets/influencers/videos/commentary/caption/en/${slug}.vtt`} captionFr={`assets/influencers/videos/commentary/caption/fr/${slug}.vtt`} />) : ''}
        

        <div className={style.quote}>
          <div className={style.container} data-aos="fade-up">
            <q>
              {quote ? (
                quote
              ) : (
                <Skeleton
                  width={200}
                  baseColor="#faf8f6"
                  highlightColor="#7adef5"
                  duration={0.9}
                  count={2}
                />
              )}
            </q>
          </div>
        </div>
        <PrideImg />
      </div>
    </>
  );
};

export default Influencer;
