import React, {useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import Styles from "./Banners.module.scss";
import Env from "../../Utils/Partials/Constant";
import Bggradient from '../../Utils/Partials/Assets/Images/Bggradient.jpg';
import HomeBanner from '../../Utils/Partials/Assets/Images/HomeBanner.png';


function Banners() {

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <div className={Styles.home_banners_parent}>
      {/* <div className={Styles.banner_bar} style={{backgroundImage:  `url(${Env.APP_URL}/assets/Images/Bggradient.jpg)`}}></div> */}
      
      <div className={Styles.videoOverlay}></div>
      <video  className={Styles.home_banner_image}
          style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
          playsInline
          loop
          autoPlay
          muted
          alt="All the devices"
          src={`${Env.APP_URL}/assets/pride-banner.mp4`}
          ref={videoEl}
        />
    </div>
  );
}

export default Banners;
