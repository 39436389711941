function checkLang(i18n,lang) {
    if (lang==='en') {
        i18n.changeLanguage('en');
    }
    else if(lang==='fr')
    {
        i18n.changeLanguage('fr');
    }
    else {
        i18n.changeLanguage('en');
    }
  }
export {checkLang}