import React, {useEffect }  from "react";
import Styles from "./Stories.module.scss";
import Env from "../../Utils/Partials/Constant";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Stories() {
  useEffect(() => { 
    AOS.init({
      once: true
    }); 
  }, []) 
  const { t } = useTranslation();
  return (
    <div className={`${Styles.home_stories_parent} storiesCustom`}>
        <div className={Styles.container}>
            <div className={Styles.stories_inner} data-aos="fade-up">
              <h1 dangerouslySetInnerHTML={{ __html: t("home.stories.main_title") }} />
              <h5 dangerouslySetInnerHTML={{ __html: t("home.stories.sub_title") }} /> 
              <p dangerouslySetInnerHTML={{ __html: t("home.stories.para_one") }} /> 
              <p dangerouslySetInnerHTML={{ __html: t("home.stories.para_two") }} /> 
              
            </div>
        </div>
    </div>
  );
}

export default Stories;
