import React from 'react';
import Banners from './Banner/Banners';
import Stories from './Story/Stories';
import Influencers from './Influencer/Influencers';
import Narratives from './Narrative/Narratives';
import Prides from './Pride/Prides';
const Home = () => {
  return (
    <>
    <Banners />
    <Stories />
    <Influencers />
    <Narratives />
    <Prides />
    </>
  );
};

export default Home;
