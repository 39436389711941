export default Object.freeze({
    COMING_SOON: false, //true.  false
    
    //staging
    /**/
    APP_EN_URL: 'https://notjustforshow.slz.io',
    APP_FR_URL: 'https://pasjustepourleshow.slz.io',
    APP_URL: 'https://notjustforshow.slz.io',
    // APP_URL: 'https://pasjustepourleshow.slz.io',

    // Prod
    /*
    APP_EN_URL: 'https://www.notjustforshow.ca',
    APP_FR_URL: 'https://www.pasjustepourleshow.ca',
    APP_URL: 'https://www.notjustforshow.ca',
    // APP_URL: 'https://www.pasjustepourleshow.ca',
    /**/
   
    // for local
    // APP_EN_URL: 'http://localhost:3000',
    // APP_FR_URL: 'http://localhost:3000',
    // APP_URL: 'http://localhost:3000',

    // adjust meta tags based on language in index.html

    APP_LANG: 'en', //fr, en
    PRIME:'//primevideo.ca',
    PRIDE_TORONTO : '//pridetoronto.com',
    PRIVACY : 'https://www.amazon.ca/gp/help/customer/display.html?nodeId=918814',
    PRIVACY_FR : 'https://www.amazon.ca/gp/help/customer/display.html?nodeId=918814',
    TERMS : 'https://www.amazon.ca/gp/help/customer/display.html?ref_=hp_left_v4_sib&nodeId=GLSBYFE9MGKKQXXM',
    TERMS_FR : 'https://www.amazon.ca/gp/help/customer/display.html?ref_=hp_left_v4_sib&nodeId=GLSBYFE9MGKKQXXM',
    GET_IN_TOUCH : 'https://www.primevideo.com/help',
    GLAMAZON : 'https://www.aboutamazon.com/news/tag/glamazon',
    GLAMAZON_FR : 'https://www.aboutamazon.fr/',
    INCLUSION_PLAYBOOK   : 'https://www.ccdeia.com/playbook',
    FB:'https://www.facebook.com/PrimeVideoCA/',
    IG:'https://www.instagram.com/primevideoca/',
    IG_FR: 'https://www.instagram.com/alloprimevideo/',
    TW:'https://twitter.com/PrimeVideoCA',
    TT:'https://www.tiktok.com/@primevideoca',
    YT:'https://www.youtube.com/@PrimeVideoCA/videos',
    
    
});