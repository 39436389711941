import React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsYoutube, BsInstagram, BsTwitter, BsTiktok } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import Env from '../Constant';
import { useTranslation } from 'react-i18next';
import PrimeLogo from '.././Assets/Images/Prime_Logo_RGB_WHITE.svg';
import Bggradient from '.././Assets/Images/Bggradient.jpg';
import Bgfacebook from '.././Assets/Images/facebookBg.jpg';
import Bginstagram from '.././Assets/Images/instagramBg.jpg';
import Bgtwitter from '.././Assets/Images/twitterBg.jpg';
import Bgtiktok from '.././Assets/Images/tiktokBg.jpg';
import Bgyoutube from '.././Assets/Images/youtubeBg.jpg';
export default function Footer() {
    const { t } = useTranslation();
    const {lang} = useParams();
    return (
        <>
            <div className='footerBgImg' style={{backgroundImage:  `url(${Bggradient})`}}>
                <div className='footerBg'>
                    <div className='footerTop'>
                        <div className='container'>
                            <div className='footerLogo'>
                                <Link to={Env.PRIME} target='_blank'><img src={`${Env.APP_URL}/assets/Images/Prime_Logo_RGB_WHITE.svg`} alt="PrimeLogo" /></Link>
                            </div>
                            <div className='footerLink' >
                                <ul>
                                    <li>
                                        <Link to={Env.APP_LANG == 'en' ? Env.PRIVACY : Env.PRIVACY_FR } target='_blank'>
                                        {t("footer.footermenu.privacy")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={ Env.APP_LANG == 'en' ? Env.TERMS : Env.TERMS_FR} target='_blank'>
                                        {t("footer.footermenu.terms")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={Env.GET_IN_TOUCH} target='_blank'>
                                        {t("footer.footermenu.touch")}
                                        </Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to={ Env.APP_LANG == 'en' ? Env.GLAMAZON : Env.GLAMAZON_FR} target='_blank'>
                                        {t("footer.footermenu.glamazon")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={Env.INCLUSION_PLAYBOOK} target='_blank'>
                                            {t("footer.footermenu.inclusion")}
                                        </Link>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                    <div className='foterBar'  style={{backgroundImage: `url(${Env.APP_URL}/assets/Images/Bggradient.jpg)`}}></div>
                    <div className='footerSocial'>
                        <div className='container'>
                            <ul className="ft_social">
                                <li style={{backgroundImage: `url(${Env.APP_URL}/assets/Images/facebookBg.jpg)`}}>
                                    <Link to={Env.FB} target='_blank'>
                                        <FaFacebookF />
                                    </Link>
                                </li>
                                <li style={{backgroundImage: `url(${Env.APP_URL}/assets/Images/instagramBg.jpg)`}}>
                                    <Link to={Env.APP_LANG == 'en' ? Env.IG : Env.IG_FR} target='_blank'>
                                        <BsInstagram />
                                    </Link>
                                </li>
                                <li style={{backgroundImage: `url(${Env.APP_URL}/assets/Images/twitterBg.jpg)`}}>
                                    <Link to={Env.TW} target='_blank'>
                                        <BsTwitter />
                                    </Link>
                                </li>
                                <li style={{backgroundImage: `url(${Env.APP_URL}/assets/Images/tiktokBg.jpg)`}}>
                                    <Link to={Env.TT} target='_blank'>
                                        <BsTiktok />
                                    </Link>
                                </li>
                                <li style={{backgroundImage: `url(${Env.APP_URL}/assets/Images/youtubeBg.jpg)`}}>
                                    <Link to={Env.YT} target='_blank'>
                                        <BsYoutube />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='footerBottom'>
                        <div className='container'>
                            <p>© {t("footer.copyright.paracopyright")} 2023.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
